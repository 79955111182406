import axios from 'axios'
import _, { find, includes } from 'lodash'
import { apiClient } from '../axios.config'

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const getUpdated = (id: number, data: Municipality[]) => find(data, { id })

export const getPrice = (incomingPrice: number | null | undefined, locale: string) => {
  const price = incomingPrice ?? 0
  return locale ? price.toLocaleString(locale) : price
}

export const convertAllImagesToBase64 = async (
  proxyURL: string,
  cloned: HTMLElement,
  stripHTTP = true,
) => {
  const pendingImagesPromises = []
  const pendingPromisesData: {
    index: number
    resolve: (value: unknown) => void
    reject: (reason?: unknown) => void
  }[] = []

  const images = cloned.getElementsByTagName('img')

  for (let i = 0; i < images.length; i += 1) {
    // First we create an empty promise for each image
    const promise = new Promise((resolve, reject) => {
      pendingPromisesData.push({
        index: i,
        resolve,
        reject,
      })
    })
    // We save the promise for later resolve them
    pendingImagesPromises.push(promise)
  }

  for (let i = 0; i < images.length; i += 1) {
    // Strip HTTP(S) from URL
    const url = stripHTTP ? images[i].src.replace(/^https?:\/\//, '') : images[i].src
    // We fetch the current image
    axios
      .get(`${proxyURL}?url=${url}`)
      .then((response) => response.data)
      .then((data) => {
        const pending = pendingPromisesData.find((p) => p.index === i)
        const imgCallback = () => {
          pending?.resolve(data)
        }

        images[i].onload = imgCallback
        images[i].src = `data:image/jpg;base64,${data}`
      })
      .catch((e) => {
        const pending = pendingPromisesData.find((p) => p.index === i)
        images[i].src = includes(images[i].src, 'base64') ? images[i].src : 'data:image/png;base64,'
        pending?.resolve('')
        // pending?.reject(e)
      })
  }

  // This will resolve only when all the promises resolve
  return Promise.all(pendingImagesPromises)
}

export const getProductIdsFromSelections = (selections: ProductGroupCategory[]) =>
  _(selections).flatMap('productGroups').flatMap('products').map('id').value()

export const getProductsFromSelections = (selections: ProductGroupCategory[]) =>
  _(selections).flatMap('productGroups').flatMap('products').value()

export const base64Image = async (src: string) =>
  apiClient
    .get('base64', {
      params: {
        url: src.replace(/^https?:\/\//, ''),
      },
    })
    .then((response) => response.data)
    .then((b64) => `data:image/png;base64,${b64}`)

export const getDynamicImageSrc = (
  src: string,
  width?: number,
  height?: number,
  scalemode?: string,
) => {
  if (includes(src, 'blob.core.windows.net/pictures')) {
    const filename = src.replace(/^.*[\\/]/, '')
    const url = new URL(`${process.env.BYGGONLINE_API_URL}image/${filename}`)
    const searchParams = new URLSearchParams()
    width && searchParams.set('width', `${Math.round(width)}`)
    height && searchParams.set('height', `${Math.round(height)}`)
    scalemode && searchParams.set('scalemode', scalemode)
    url.search = searchParams.toString()
    return url.href
  }

  return src
}
