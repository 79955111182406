import _, {
  each,
  fill,
  find,
  flatMap,
  get,
  includes,
  isEmpty,
  map,
  orderBy,
  size,
  some,
} from 'lodash'
import XpoUrlImageTypes = UrlGeneratorModule.XpoUrlImageTypes
import XpoUrlRenderModes = UrlGeneratorModule.XpoUrlRenderModes
import XpoUrlResizeMethods = UrlGeneratorModule.XpoUrlResizeMethods
import XpoUrlFileTypes = UrlGeneratorModule.XpoUrlFileTypes
import { getProductsFromSelections } from './utils'
import mergeImages from 'merge-images'

const fluentXpoUrlFactory = new FluentXpoUrlFactory()
const xpoUrlGenerator = new XpoUrlGenerator()

export const parseXpoProperties = (obj: any) => {
  obj.properties = _(obj.properties).mapKeys('name').mapValues('value').value()
}

export const getRenderUrl = (
  xpoLayer: any,
  containerSize: { width?: number; height?: number },
  imageType: 'Image' | 'Scene' | 'Design',
  products?: Product[],
  selectedDesigns?: Design[],
  selectedColors?: Color[],
) => {
  const fluentUrlGenerator = fluentXpoUrlFactory
    .createFluentUrlGenerator(xpoUrlGenerator, UrlGeneratorModule.FluentXpoUrlType.Image)
    .setAbsoluteUrl(process.env.XPO_URL ?? '')

  const urlBuilder = fluentUrlGenerator
    .setPrimaryKey(xpoLayer.referenceId)
    .setImageType(XpoUrlImageTypes.Png)
    .setEntityType(XpoUrlFileTypes[imageType])
    .setWidth(containerSize.width ?? 1024)
    .setHeight(containerSize.height ?? 760)
    .setResizeMethod(XpoUrlResizeMethods.Crop)
    .setFastRender(false)
    .setSceneRenderMode(XpoUrlRenderModes.Normal)

  each(xpoLayer.sceneObjects, (s, index) => {
    const product = get(products, [index])
    const design = product && getDesignForProduct(product, selectedDesigns)
    const color =
      (selectedColors && selectedColors[product?.groupId])?.hexValue ??
      get(product, ['colors', 0, 'hexValue'], undefined)

    urlBuilder.addObject((obj: FluentXpoUrlObject) => {
      if (design || color) {
        design ? obj.design(design ?? '') : obj.color(color.replace('#', ''))
        obj.setIndex(+index)
      }
    })
  })

  return urlBuilder.getUrl()
}

export const getDesignForProduct = (product: Product, designs: any) => {
  const d = find(
    designs,
    (design, index) =>
      parseInt(index) === product?.groupId &&
      includes(design?.properties?.articleNumbers, `${product.articleNr}`),
  )
  return d?.referenceId
}

export const getXpoArticleNumbersFromResource = (resource: any) => {
  const length = resource.sceneObjects ? size(resource.sceneObjects) : 1

  if (includes(resource.properties.articleNumbers, ':')) {
    return _(Array(+length))
      .map((item, index) => {
        const values = _(resource.properties.articleNumbers.replace(/\s/g, ''))
          .split('|')
          .find((item) => includes(item, `${index}:`))
        return _(values)
          .split(',')
          .map((v) => +v.replace(/^(.+?):/, ''))
          .value()
      })
      .value()
  }
  return resource.properties.articleNumbers
    ? fill(
        Array(length),
        resource.properties.articleNumbers
          ?.replace(/\s/g, '')
          .split(',')
          .map((i: string) => +i),
      )
    : []
}

export const checkIfResourceShouldRender = (
  properties: XPOCustomProperties,
  xpoResources: any,
  selectedProducts: ProductGroupCategory[],
) => {
  const { renderWhen } = properties

  if (renderWhen) {
    const shouldRender: boolean[] = []
    _(renderWhen)
      .split(',')
      .each((refId) => {
        const renderIfDependantIsHidden = includes(refId, '!')
        const referenceId = refId.replace('!', '')
        const resource = find(xpoResources, { referenceId })
        const articleNumbers = getXpoArticleNumbersFromResource(resource)
        const productArticleNr = _(map(selectedProducts, 'articleNr'))
          .filter((val) => includes(flatMap(articleNumbers), +val))
          .first()

        shouldRender.push(renderIfDependantIsHidden ? !productArticleNr : !!productArticleNr)
      })
    return !some(shouldRender, (b) => !b)
  }
  return true
}

export const getRenderedImage = async (
  selections: ProductGroupCategory[],
  xpoImages,
  xpoScenes,
  xpoDesigns,
  selectedColors,
  width,
  height,
) => {
  const selectedProducts = getProductsFromSelections(selections)
  const baseImgs = _(xpoImages)
    .filter((image: any) => image.properties.base)
    .map((image: any) => {
      const shouldRender = checkIfResourceShouldRender(
        image.properties,
        xpoScenes,
        selectedProducts,
      )
      return {
        zIndex: image.properties.zIndex,
        referenceId: image.referenceId,
        imgUrl: getRenderUrl(image, { width, height }, 'Image'),
        visible: shouldRender,
      }
    })
    .value()

  const images = _(xpoImages)
    .filter((image: any) => !image.properties.base)
    .map((image: any) => {
      const articleNumbers = getXpoArticleNumbersFromResource(image)
      const productArticleNr = _(map(selectedProducts, 'articleNr'))
        .filter((val) => includes(flatMap(articleNumbers), +val))
        .first()

      const shouldRender = checkIfResourceShouldRender(
        image.properties,
        xpoScenes,
        selectedProducts,
      )

      return {
        zIndex: image.properties.zIndex,
        referenceId: image.referenceId,
        imgUrl: getRenderUrl(image, { width, height }, 'Image'),
        visible: !!productArticleNr && shouldRender,
      }
    })
    .value()

  const scenes = _(xpoScenes)
    .map((scene: any) => {
      const articleNumbers = getXpoArticleNumbersFromResource(scene)
      const selectedProductsInScene = _(selectedProducts)
        .filter((p) => includes(flatMap(articleNumbers), +p.articleNr))
        .value()

      const products = map(articleNumbers, (articleNrs) =>
        _(articleNrs)
          .map((nr) => find(selectedProductsInScene, { articleNr: nr }))
          .compact()
          .first(),
      )

      const shouldRender = checkIfResourceShouldRender(
        scene.properties,
        xpoScenes,
        selectedProducts,
      )

      return {
        zIndex: scene.properties.zIndex,
        referenceId: scene.referenceId,
        // imgUrl: getRenderUrl(scene, containerSize, 'Scene', get(product, ['colors', 0, 'hexValue'], undefined), getDesignForProduct(product?.articleNr, xpoDesigns)),
        imgUrl: getRenderUrl(
          scene,
          { width, height },
          'Scene',
          products,
          xpoDesigns,
          selectedColors,
        ),
        sceneObjects: scene.sceneObjects,
        articleNumbers,
        visible: !isEmpty(selectedProductsInScene) && shouldRender,
      }
    })
    .compact()
    .value()

  const layers = orderBy([...baseImgs, ...images, ...scenes], 'zIndex') as XPOScene[]
  const imgs = _(orderBy(layers, 'zIndex'))
    .map((image) => (image.visible ? image.imgUrl : null))
    .compact()
    .value()

  return mergeImages(imgs, {
    crossOrigin: '',
  })
}
