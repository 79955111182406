import { get, isEqual, split } from 'lodash'
import store from '../store/createStore'

const useAsyncDispatch = () => (action: any, valueStorePath: string) => {
  let originalValue = get(store.getState(), split(valueStorePath, '.'))

  return new Promise((resolve) => {
    if (isEqual(originalValue, action.payload)) {
      return resolve(action.payload)
    }
    const listener = () => {
      const value = get(store.getState(), split(valueStorePath, '.'))

      if (!isEqual(value, originalValue)) {
        originalValue = value
        resolve(value)
        subscription()
      }
    }
    const subscription = store.subscribe(listener)

    const { dispatch } = store
    dispatch(action)
  })
}

export default useAsyncDispatch
