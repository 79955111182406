import { FC, JSXElementConstructor, ReactElement, ReactNode } from 'react'
import * as RadixPopover from '@radix-ui/react-popover'

interface Props {
  content: ReactElement<unknown, string | JSXElementConstructor<unknown>>
  children: ReactNode
}

const Popover: FC<Props> = ({ content, children }) => (
  <div className="popover">
    <RadixPopover.Root>
      <RadixPopover.Trigger>{children}</RadixPopover.Trigger>
      <RadixPopover.Anchor />
      <RadixPopover.Content sideOffset={5} style={{ maxWidth: '260px' }}>
        <div className="bg-white p-4 rounded">{content}</div>
        <RadixPopover.Close />
        <RadixPopover.Arrow className="fill-white" />
      </RadixPopover.Content>
    </RadixPopover.Root>
  </div>
)

export default Popover
