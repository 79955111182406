import * as styles from './ServiceWorkerUpdate.module.scss'
import Button from './Button'

const ServiceWorkerUpdate = () => (
  <div id="sw-update" className={styles.wrapper} style={{ maxHeight: 0 }}>
    <div className={styles.inner}>
      Det finns en ny uppdatering.
      <Button size="sm" id="sw-update-btn">
        Uppdatera nu
      </Button>
    </div>
  </div>
)

export default ServiceWorkerUpdate
