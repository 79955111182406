import { useEffect, useLayoutEffect, useState } from 'react'
import { Outlet, useLocation, useMatches, useNavigate, useSearch } from '@tanstack/react-location'
import { last, get } from 'lodash'
import { useQueryClient } from 'react-query'
import ReactGA from 'react-ga4'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import usePrevious from '../hooks/usePrevious'
import {
  useAppSelector,
  useAppDispatch,
  useAppSelector,
  useAppDispatch,
} from '../store/createStore'
import ServiceWorkerUpdate from '../components/ServiceWorkerUpdate'
import Header from './Header'
import useSelectedHouse from '../hooks/useSelectedHouse'

const Layout = () => {
  const { selectedHouse } = useSelectedHouse()
  const { quickConfigCode } = useAppSelector((state) => state.builder)
  const location = useLocation()
  const search = useSearch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [metaData, setMetaData] = useState({
    name: t('houseChooser_buildHouseOnline'),
    description: t('houseChooser_generalInfo'),
    image:
      'https://fiskarhedenvillan.se/app/uploads/2022/05/atmosfar121_webb-1200x674-1024x575.jpg',
  })

  const dispatch = useAppDispatch()
  const { building } = useAppSelector((state) => state.builder)
  const { selectedMunicipality } = useAppSelector((state) => state.builder)
  const previousCurrencyCode = usePrevious(selectedMunicipality?.currencyCode)
  const queryClient = useQueryClient()

  const matches = useMatches()
  const route = get(last(matches), 'route') as CustomRoute

  useEffect(() => {
    if (!building.externalBuildingId && route.path !== ':code') {
      navigate({ to: '/' })
    } else if (search.externalBuildingId || search.houseModel) {
      navigate({ to: '/builder' })
    }
  }, [route.path, building.externalBuildingId, search.externalBuildingId])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 1)
    }, 1)

    ReactGA.send({ hitType: 'pageview', page: location.current.pathname })
  }, [location.current.pathname])

  useLayoutEffect(() => {
    if (route.title) {
      document.title = route?.title
    }
  }, [route.title])

  useEffect(() => {
    if (selectedMunicipality?.currencyCode !== previousCurrencyCode) {
      queryClient.invalidateQueries()
    }
  }, [selectedMunicipality?.currencyCode, previousCurrencyCode])

  useLayoutEffect(() => {
    if (selectedHouse) {
      setMetaData({
        name: `Byggonline - ${selectedHouse?.name}`,
        description: selectedHouse?.description,
        image: selectedHouse?.images[0],
      })
    }
  }, [location.current.pathname, quickConfigCode, selectedHouse])

  return (
    <>
      <ServiceWorkerUpdate />
      <Helmet
        title={metaData.name}
        meta={[
          { property: 'og:image', content: metaData?.image },
          { property: 'og:description', content: metaData?.description },
          { property: 'og:site_name', content: 'byggonline.fiskarhedenvillan.se' },
          { property: 'og:url', content: 'https://byggonline.fiskarhedenvillan.se' },
          { property: 'og:site_name', content: 'byggonline.fiskarhedenvillan.se' },
        ]}
      />
      <Header />
      <main>
        <Outlet />
      </main>
    </>
  )
}

export default Layout
