import { FC, ReactNode } from 'react'
import * as RadixToast from '@radix-ui/react-toast'
import { AiFillCloseCircle } from 'react-icons/ai'
import classNames from 'classnames'
import * as styles from './Toast.module.scss'

interface ToastProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  title: string
  description: string
  duration?: number
  error?: boolean
  children?: ReactNode
}

const Toast: FC<ToastProps> = ({
  open,
  onOpenChange,
  title,
  description,
  duration,
  error,
  children,
}) => (
  <RadixToast.ToastProvider swipeDirection="right">
    <RadixToast.Toast
      open={open}
      duration={duration ?? 5000}
      onOpenChange={onOpenChange}
      className={classNames(styles.toast, error && styles.error)}
    >
      <div className={styles.content}>
        <RadixToast.ToastTitle className={styles.title}>{title}</RadixToast.ToastTitle>
        <RadixToast.ToastAction asChild altText="Close">
          <div>
            <AiFillCloseCircle className={styles.close} />
          </div>
        </RadixToast.ToastAction>
      </div>
      <RadixToast.ToastDescription className={styles.description}>
        <div>{description}</div>
        <div>{children}</div>
      </RadixToast.ToastDescription>
    </RadixToast.Toast>
    <RadixToast.ToastViewport className={styles.viewport} />
  </RadixToast.ToastProvider>
)

export default Toast
