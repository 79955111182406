import ReactGA from 'react-ga4'

const useAnalyticsEventTracker = (category = 'Dummy event') => {
  const eventTracker = (action = '', label = '', value = null, nonInteraction = false) => {
    // ReactGA.event({ category, action, label })
    ReactGA.event({
      category,
      action,
      label, // optional
      value: value ?? undefined, // optional, must be a number
      nonInteraction, // optional, true/false
    })
  }
  return eventTracker
}
export default useAnalyticsEventTracker
