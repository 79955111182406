.toast {
  @apply bg-slate-50 mr-6 mb-6 px-4 py-2 rounded-lg drop-shadow-xl min-w-[120px];

  .content {
    @apply flex justify-between;

    .title {
      @apply text-slate-600 font-medium;
    }

    .close {
      @apply text-secondary text-lg cursor-pointer;
    }
  }

  .description {
    @apply text-slate-600 text-sm;
  }

  &[data-state='open']:global {
    animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &[data-state='closed']:global {
    animation: hide 100ms ease-in forwards;
  }

  &[data-swipe='move']:global{
    transform: translateX(var(--radix-toast-swipe-move-x));
  }

  &[data-swipe='cancel']:global {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }

  &[data-swipe='end']:global {
    animation: swipeOut 100ms ease-out forwards;
  }

  &.error {
    @apply bg-red-800;

    .title, .description, .close {
      @apply text-gray-200;
    }
  }
}

.viewport {
  @apply fixed bottom-0 right-0;
}

//@keyframes hide {
//  0% { opacity: 1 }
//  100% { opacity: 0 }
//}
//
//@keyframes slideIn {
//  from { transform: translateX(calc(100% + 2rem)) }
//  to { transform: translateX(0) }
//}
//
//@keyframes swipeOut {
//  from { transform: translateX(var(--radix-toast-swipe-end-x)) }
//  to { transform: translateX(calc(100% + 2rem)) }
//}
