import { useMemo } from 'react'
import { useQuery } from 'react-query'
import _, { find, sumBy } from 'lodash'
import { apiClient, xpoApiClient } from '../axios.config'
import { useAppDispatch, useAppSelector } from '../store/createStore'
import { removeFromBuilding } from '../store/slices/builderSlice'
import { parseXpoProperties } from '../utils/xpoUtils'

const useProductData = (): {
  productData: {
    productGroupCategories: ProductGroupCategory[]
    designLookup: Map<string, Design[]>
  }
  selections: ProductGroupCategory[] | null
  selectedColors: Color[]
  isLoading: boolean
} => {
  const { building, selectedColors } = useAppSelector((state) => state.builder)
  const dispatch = useAppDispatch()

  const { data, isLoading } = useQuery(
    ['productData', building.externalBuildingId],
    () =>
      Promise.allSettled([
        apiClient.get(`options/${building.externalBuildingId}`).then((response) => response.data),
        xpoApiClient
          .get('designs/dump')
          .then((response) => response.data)
          .then((designResponse) => {
            _(designResponse).each(parseXpoProperties)
            return designResponse.reduce((t, d) => {
              d.properties.articleNumbers.split(',').forEach((n) => {
                const val = t.get(n)
                if (!val) t.set(n, [d])
                else t.set(n, [...val, d])
              })
              return t
            }, new Map())
          }),
      ]).then((results) => {
        results[0].value.designLookup = results[1].value
        return results[0].value
      }),
    { enabled: !!building.externalBuildingId },
  )

  const selections = useMemo(() => {
    if (data && !isLoading) {
      return _(building.productGroupCategories)
        .map((category) => {
          const dataCategory = find(data.productGroupCategories, { id: category.id })
          const mCategory = {
            id: category.id,
            name: dataCategory?.name,
            totalPrice: 0,
            productGroups: _(category.productGroups)
              .map((group) => {
                const dataGroup = find(dataCategory?.productGroups, { id: group.id })

                const products = _(group.products)
                  .map((product) => {
                    const dataProduct = find(dataGroup?.products, { id: product.id })
                    if (dataProduct) {
                      return {
                        ...product,
                        name: dataProduct.name,
                        images: dataProduct.images,
                        articleNr: +dataProduct.articleNr,
                        priceInfo: dataProduct.priceInfo,
                        colors: dataProduct.colors,
                        groupName: dataGroup?.name,
                        groupId: dataGroup?.id,
                      }
                    }
                    dispatch(
                      removeFromBuilding({
                        productGroupCategoryId: category.id,
                        productGroupId: group.id,
                        productId: product.id,
                      }),
                    )
                    return null
                  })
                  .compact()
                  .value()

                return {
                  ...group,
                  name: dataGroup?.name,
                  products,
                  totalPrice: sumBy(products, (p) => p.priceInfo?.totalPrice ?? 0),
                }
              })
              .compact()
              .value(),
          }

          mCategory.totalPrice = sumBy(mCategory.productGroups, 'totalPrice')

          return mCategory
        })
        .compact()
        .value()
    }

    return null
  }, [data, building, isLoading])

  return { productData: data, selections, selectedColors, isLoading }
}

export default useProductData
