import { createRoot } from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import './registerServiceWorker'
import { PersistGate } from 'redux-persist/integration/react'
import './i18n'
import ReactGA from 'react-ga4'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import TagManager from 'react-gtm-module'
import store, { persistor } from './store/createStore'

process.env.NODE_ENV !== 'development' &&
  Sentry.init({
    environment:
      window.location.href.indexOf('byggonlinetest') > -1 ? 'staging' : process.env.NODE_ENV,
    dsn: 'https://f8628f8b38764508a5be8cad89575434@o612383.ingest.sentry.io/6492788',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  })

const TRACKING_ID = process.env.NODE_ENV === 'production' ? 'G-V093024524' : 'G-TN6XRHYQWC'
ReactGA.initialize([
  {
    trackingId: TRACKING_ID,
    gaOptions: { debug: process.env.NODE_ENV === 'development' }, // optional
    gtagOptions: {}, // optional
  },
])

const tagManagerArgs = {
  gtmId: process.env.GTM_ID ?? '',
}
// Only enable GTM if a GTM ID is set
tagManagerArgs.gtmId && TagManager.initialize(tagManagerArgs)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60,
    },
  },
})

const setLangAttribute = () => {
  // Redux is not available at this point so just manually grab and parse from local storage
  const rawStore = window.localStorage.getItem(`persist:byggonline_${process.env.NODE_ENV}`)
  const parsedStore = rawStore && JSON.parse(rawStore)
  const appStore = parsedStore?.app && JSON.parse(parsedStore.app)
  document.documentElement.lang = appStore?.lang?.toLowerCase() ?? 'sv'
}

setLangAttribute()
const container = document.getElementById('app') as HTMLElement
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </PersistGate>
  </Provider>,
)
