import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loadingFirstRender: false,
}

export const renderingSlice = createSlice({
  name: 'rendering',
  initialState,
  reducers: {
    setLoadingFirstRender: (state, action) => {
      state.loadingFirstRender = action.payload
    },
  },
})

export const { setLoadingFirstRender } = renderingSlice.actions

export default renderingSlice.reducer
