import { useLocation } from '@tanstack/react-location'
import { useMemo } from 'react'
import { useAppSelector } from '../store/createStore'

const useStaleContent = () => {
  const location = useLocation()
  const houseBuilder = useMemo(() => location.current.pathname !== '/', [location.current.pathname])
  const { builder } = useAppSelector((state) => state)

  const { previousPriceList, publishedPriceList } = builder

  return !!(houseBuilder && previousPriceList && !publishedPriceList)
}

export default useStaleContent
