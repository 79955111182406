import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  lang: 'SV',
  objectId: null,
}

const acceptedLangs = ['SV', 'NO']

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSelectedLang: (state, action) => {
      state.lang = !acceptedLangs.includes(action.payload) ? 'SV' : action.payload
    },
    setObjectId: (state, action) => {
      state.objectId = action.payload
    },
  },
})

export const { setSelectedLang, setObjectId } = appSlice.actions

export default appSlice.reducer
