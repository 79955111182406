import axios from 'axios'
import { find, set } from 'lodash'
import store from './store/createStore'
import { setErrors } from './store/slices/errorSlice'

export const apiClient = axios.create({
  baseURL: process.env.BYGGONLINE_API_URL,
  // timeout: 1000
})

apiClient.interceptors.request.use((config) => {
  const storeState = store.getState()

  if (storeState?.app.lang) {
    set(config, 'params.countryCode', storeState?.app.lang)
  }

  if (storeState?.builder.selectedMunicipality?.currencyCode) {
    set(config, 'params.currencyCode', storeState?.builder.selectedMunicipality?.currencyCode)
  }

  if (storeState?.builder.priceListId) {
    set(config, 'params.priceListId', storeState?.builder.priceListId)
  }

  return config
})

apiClient.interceptors.response.use(
  (config) => {
    if (config.status === 204) {
      throw new Error('Error code 204')
    }
    return config
  },
  (error) => {
    const errors = store.getState()?.error.errors
    const params = new URLSearchParams(error.config.params)
    const key = `${error.config.url}/${params.toString()}`
    if (!find(errors, { key })) {
      store.dispatch(setErrors([...errors, { key, message: error.message }]))
    }
    return Promise.reject(error)
  },
)

export const wpApiClient = axios.create({
  auth: {
    username: process.env.WP_CREDENTIALS?.split(':')[0] ?? '',
    password: process.env.WP_CREDENTIALS?.split(':')[1] ?? '',
  },
  baseURL: process.env.WP_API_URL,
  headers: {},
})

export const xpoApiClient = axios.create({
  baseURL: `${process.env.XPO_URL}/xpo/api/v2`,
  headers: {
    accept: 'application/json',
    apiKey: process.env.XPO_API_KEY ?? '',
  },
})
