import { useQueryClient } from 'react-query'
import React, { useEffect } from 'react'
import i18next from 'i18next'
import * as Popover from '@radix-ui/react-popover'
import sweFlag from './SE.svg'
import noFlag from './NO.svg'
import { useAppDispatch, useAppSelector } from '../../store/createStore'
import { setSelectedLang } from '../../store/slices/appSlice'

const LangSelector = () => {
  const { lang: selectedLang } = useAppSelector((state) => state.app)
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const triggerRef = React.useRef<HTMLButtonElement>(null)

  const handleChangeLang = () => {
    dispatch(setSelectedLang(selectedLang === 'SV' ? 'NO' : 'SV'))
    triggerRef.current?.click()
  }

  useEffect(() => {
    queryClient.invalidateQueries()
    i18next.changeLanguage(selectedLang.toLowerCase())
  }, [selectedLang])

  return (
    <div className="w-9 h-7">
      <Popover.Root>
        <Popover.Trigger className="w-full h-full" ref={triggerRef}>
          <div className="cursor-pointer p-1">
            <img src={selectedLang === 'SV' ? sweFlag : noFlag} alt="" />
          </div>
        </Popover.Trigger>
        <Popover.Anchor />
        <Popover.Content className="w-full h-full">
          <div className="w-9 h-7 p-1 bg-slate-300 cursor-pointer" onClick={handleChangeLang}>
            <img src={selectedLang === 'SV' ? noFlag : sweFlag} alt="" />
          </div>
          <Popover.Arrow className="fill-slate-300" />
        </Popover.Content>
      </Popover.Root>
    </div>
  )
}

export default LangSelector
