import React, { ButtonHTMLAttributes, ReactNode } from 'react'
import classNames from 'classnames'
import { upperFirst } from 'lodash'
import * as styles from './Button.module.scss'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string
  size?: string
  fullWidth?: boolean
  tag?: 'div' | 'button'
  onClick?: () => void
  children?: ReactNode
}

const Button: React.FC<ButtonProps> = ({
  children,
  color = 'secondary',
  size = 'lg',
  fullWidth,
  tag: Wrapper = 'button',
  type = 'button',
  onClick,
  className,
  ...props
}) => {
  const extraProps = Wrapper === 'div' ? {} : { type }

  return (
    <Wrapper
      className={classNames(
        styles.btn,
        styles[`btn${upperFirst(color)}`],
        styles[`btn${upperFirst(size)}`],
        fullWidth && styles.btnFullWidth,
        className,
      )}
      onClick={onClick}
      {...extraProps}
      {...props}
    >
      <div className="flex justify-center items-center">{children}</div>
    </Wrapper>
  )
}

export default Button
