import { combineReducers, configureStore, Reducer } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import appReducer from './slices/appSlice'
import builderReducer from './slices/builderSlice'
import renderingReducer from './slices/renderingSlice'
import errorReducer from './slices/errorSlice'

export const persistConfig = {
  key: `byggonline_${process.env.NODE_ENV}`,
  storage,
  blacklist: ['rendering', 'error'],
  stateReconciler: autoMergeLevel2,
}

const persistedReducer: Reducer = persistReducer<RootState>(
  persistConfig,
  combineReducers({
    app: appReducer,
    builder: builderReducer,
    rendering: renderingReducer,
    error: errorReducer,
  }),
)

const rootReducer: Reducer = (state, action) =>
  // if (action.type === 'app/setSelectedLang') {
  //   state = {
  //     ...state,
  //     _persist: state._persist,
  //     builder: {
  //       ...state.builder,
  //       building: {
  //         externalBuildingId: undefined,
  //         productGroupCategories: [] as ProductGroupCategory[]
  //       }
  //     }
  //   }
  // }

  persistedReducer(state, action)

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const persistor = persistStore(store)

export default store
