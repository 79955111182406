.wrapper {
  @apply w-full h-auto bg-slate-500 text-white z-50 transition-all duration-300 fixed;
  top: 0;
  left: 0;
  max-height: 0;
  overflow: hidden;
  box-shadow: inset 0 -3px 4px 0 rgba(0,0,0,0.45);

  .inner {
    @apply p-4 flex justify-between items-center ;
  }
}
