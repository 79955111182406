import { useQuery } from 'react-query'
import { useMemo } from 'react'
import { apiClient } from '../axios.config'
import { useAppSelector } from '../store/createStore'

const useSelectedHouse = () => {
  const houseId = useAppSelector((state) => state.builder.building.externalBuildingId)

  const { data: selectedHouse, isLoading: isLoadingHouse } = useQuery<House>(
    ['single_house', houseId],
    () => apiClient.get(`HouseModel/${houseId}`).then((response) => response.data),
    { staleTime: 15000, enabled: !!houseId },
  )

  return { selectedHouse, isLoading: isLoadingHouse }
}

export default useSelectedHouse
