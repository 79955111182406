if ('serviceWorker' in navigator) {
  // Use the window load event to keep the page load performant
  window.addEventListener('load', () => {
    if (process.env.NODE_ENV === 'production') {
      navigator.serviceWorker
        .register(new URL('./sw.ts', import.meta.url), { type: 'module' })
        .then((registration) => {
          setInterval(() => {
            registration.update()
          }, 5000)

          registration.onupdatefound = (ev) => {
            const installedWorker = registration.installing

            if (installedWorker) {
              installedWorker.onstatechange = function () {
                if (installedWorker.state === 'installed') {
                  const wrapper = document.querySelector('#sw-update') as HTMLDivElement
                  const header = document.querySelector('#header') as HTMLDivElement
                  const button = document.querySelector('#sw-update-btn')
                  if (wrapper && button) {
                    button.addEventListener('click', () => {
                      installedWorker.postMessage({
                        type: 'SKIP_WAITING',
                      })
                    })
                    wrapper.style.maxHeight = '64px'
                    header.style.top = '64px'
                  }
                }

                if (installedWorker.state === 'activated') {
                  window.location.reload()
                }
              }
            }
          }
        })
    }
  })
}
