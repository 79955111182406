.btn {
  @apply uppercase rounded-full text-[0.675rem] sm:text-[0.875rem] font-bold px-4 w-auto shadow flex justify-center items-center;
  @apply bg-primary hover:bg-primary-400 active:bg-primary-600 focus:outline-none focus:ring focus:ring-primary-300 text-white;

  &.btnPrimary {
    @apply bg-primary hover:bg-primary-400 active:bg-primary-600 focus:outline-none focus:ring focus:ring-primary-300 text-white;
  }

  &.btnSecondary {
    @apply bg-secondary hover:bg-secondary-400 active:bg-secondary-600 focus:outline-none focus:ring focus:ring-secondary-300;
  }

  &.btnTertiery {
    @apply bg-tertiary hover:bg-tertiary-400 active:bg-tertiary-600 focus:outline-none focus:ring focus:ring-tertiary-300;
  }

  &.btnWhite {
    @apply bg-white hover:bg-gray-100 active:bg-gray-200 focus:outline-none focus:ring focus:ring-secondary-300 text-secondary;
  }

  &.btnSm {
    @apply leading-[26px] h-[26px] sm:leading-[32px] sm:h-[32px];
  }

  &.btnLg {
    @apply leading-[16px] h-[36px] sm:leading-[46px] sm:h-[46px] whitespace-nowrap;
  }

  &.btnFullWidth {
    @apply w-full;
  }
}
