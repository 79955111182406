import { createSelector, createSlice } from '@reduxjs/toolkit'
import _, { each, forEach, isArray, memoize, set, unset } from 'lodash'
import { RootState } from '../createStore'

type BuilderState = {
  priceListId: number | null
  publishedPriceList: boolean | null
  previousPriceList: boolean | null
  quickConfigCode: string | undefined
  selectedMunicipality: string | undefined
  productGroupSection: string | undefined
  selectedColors: { [key: number]: Color }
  selectedDesigns: { [key: number]: Design }
  building: {
    externalBuildingId: string | undefined
    productGroupCategories: ProductGroupCategory[]
  }
}

const initialState = {
  priceListId: null,
  publishedPriceList: true,
  previousPriceList: false,
  quickConfigCode: undefined,
  selectedMunicipality: undefined,
  productGroupSection: undefined,
  selectedColors: {},
  selectedDesigns: {},
  building: {
    externalBuildingId: undefined,
    productGroupCategories: [] as ProductGroupCategory[],
  },
} as BuilderState

export const builderSlice = createSlice({
  name: 'builder',
  initialState,
  reducers: {
    clearAll: (state, action) => {
      const {
        payload: { externalBuildingId = undefined },
      } = action
      state.priceListId = null
      state.previousPriceList = null
      state.publishedPriceList = null
      state.quickConfigCode = undefined
      state.productGroupSection = undefined
      state.selectedColors = {}
      state.selectedDesigns = {}
      state.building = {
        externalBuildingId,
        productGroupCategories: [] as ProductGroupCategory[],
      }
    },
    setPublishedPriceList: (state, action) => {
      state.publishedPriceList = action.payload
    },
    setPriceListId: (state, action) => {
      if (!action.payload) state.priceListId = initialState.priceListId
      else state.priceListId = action.payload
    },
    setPreviousPriceList: (state, action) => {
      state.previousPriceList = action.payload
    },
    setSelectedHouse: (state, action) => {
      state.building.externalBuildingId = action.payload
    },
    setProductGroupSection: (state, action) => {
      state.productGroupSection = action.payload
    },
    addToBuilding: (state, action) => {
      const addProduct = (
        productGroupCategoryId: number,
        productGroup: ProductGroup,
        product: Product,
        selectMultiple: boolean,
        excludesProductGroups: number[],
      ) => {
        const customizer = (
          objValue: ArrayLike<object>,
          srcValue: ArrayLike<object>,
          propertyName: string,
        ) => {
          if (propertyName === 'productGroups') {
            return _.chain(_.union(srcValue, objValue))
              .groupBy('id')
              .map((v) => {
                const products = _(_(v).map('products').flattenDeep().uniqBy('id').value())
                  .map((p) => {
                    const pGroup = v[0] as ProductGroup
                    const shouldReturn =
                      pGroup.id === productGroup.id
                        ? selectMultiple || product.id === p.id
                        : !_.includes(excludesProductGroups, pGroup.id)

                    return shouldReturn
                      ? {
                          id: p.id,
                          // name: p.name,
                          // colors: _.isEmpty(p.colors) ? [] : [_.find(p.colors, { hexValue: get(state, ['selectedColors', productGroup.id, 'hexValue']) }) || p.colors[0]],
                          // priceInfo: p.priceInfo
                        }
                      : null
                  })
                  .compact()
                  .value()

                return { id: (v[0] as ProductGroup).id, products }
              })
              .value()
          }
        }

        state.building.productGroupCategories = cleanupBuilding(
          _(state.building.productGroupCategories)
            .keyBy('id')
            .mergeWith(
              _.keyBy(
                [
                  {
                    id: productGroupCategoryId,
                    productGroups: [
                      {
                        id: productGroup.id,
                        products: [{ ...product }],
                        allowedSelections: productGroup.allowedSelections,
                        excludesProductGroups: productGroup.excludesProductGroups,
                      },
                    ],
                  },
                ],
                'id',
              ),
              customizer,
            )
            .values()
            .value(),
        )
      }

      if (isArray(action.payload)) {
        each(action.payload, (p) => {
          const { productGroupCategoryId, productGroup, product, selectMultiple = false } = p
          const { excludesProductGroups } = productGroup
          addProduct(
            productGroupCategoryId,
            productGroup,
            product,
            selectMultiple,
            excludesProductGroups,
          )
        })
      } else {
        const {
          productGroupCategoryId,
          productGroup,
          product,
          selectMultiple = false,
        } = action.payload
        const { excludesProductGroups } = productGroup

        addProduct(
          productGroupCategoryId,
          productGroup,
          product,
          selectMultiple,
          excludesProductGroups,
        )
      }
    },
    removeFromBuilding: (state, action) => {
      const { productId } = action.payload

      state.building.productGroupCategories = cleanupBuilding(
        _(state.building.productGroupCategories).map((c) => {
          _.each(c.productGroups, (g) => {
            _.remove(g.products, { id: productId })
          })

          return c
        }),
      )
    },
    setColorForProductInBuilding: (state, action) => {
      const { product: newProduct, productGroup } = action.payload
      if (newProduct?.colors) {
        set(state, `selectedColors[${productGroup.id}]`, newProduct.colors[0])
      } else {
        unset(state, `selectedColors[${productGroup.id}]`)
      }
      // _(state.building.productGroupCategories).each(c => {
      //   _.each(c.productGroups, g => {
      //     const p = _.find(g.products, { id: newProduct.id })
      //     if (p) {
      //       p.colors = newProduct.colors
      //     }
      //   })
      // })
    },
    setDesignForProductInBuilding: (state, action) => {
      const { product: newProduct, productGroup } = action.payload
      if (newProduct?.designs) {
        set(state, `selectedDesigns[${productGroup.id}]`, newProduct.designs[0])
      } else {
        unset(state, `selectedDesigns[${productGroup.id}]`)
      }
    },
    setSelectedMunicipality: (state, action) => {
      state.selectedMunicipality = action.payload
    },
    setBuilderState: (state, action) => {
      forEach(action.payload, (value, key) => {
        set(state, [key], value)
      })
    },
    setQuickConfigCode: (state, action) => {
      state.quickConfigCode = action.payload
    },
  },
})

export const {
  clearAll,
  setProductGroupSection,
  setSelectedHouse,
  addToBuilding,
  removeFromBuilding,
  setColorForProductInBuilding,
  setDesignForProductInBuilding,
  setSelectedMunicipality,
  setBuilderState,
  setPublishedPriceList,
  setPreviousPriceList,
  setQuickConfigCode,
  setPriceListId,
} = builderSlice.actions

export const getSelectedProductsByProductGroupId = createSelector(
  (state: RootState) => state.builder.building.productGroupCategories,
  (categories) =>
    memoize((id) =>
      _(categories).flatMap('productGroups').filter({ id }).map('products').flattenDeep().value(),
    ),
)

export const getSelectedColorsByProductGroupId = createSelector(
  (state: RootState) => state.builder.selectedColors,
  (colors) => memoize((id) => colors[id] as Color),
)

export const getSelectedDesignsByProductGroupId = createSelector(
  (state: RootState) => state.builder.selectedDesigns,
  (designs) => memoize((id) => designs[id] as Design),
)

export default builderSlice.reducer

const cleanupBuilding = (productGroupCategories: unknown) =>
  _(productGroupCategories as ProductGroupCategory[])
    .map((c) => {
      c.productGroups = _.filter(c.productGroups, (g) => !_.isEmpty(g.products))

      return _.isEmpty(c.productGroups) ? null : c
    })
    .compact()
    .value()
