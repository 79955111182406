/* global s247r */
import './main.scss'
import { lazy, useLayoutEffect, Suspense } from 'react'
import { ReactLocation, Router, Search } from '@tanstack/react-location'
import { find, includes } from 'lodash'
import { CgSpinnerTwoAlt } from 'react-icons/all'
import Layout from './layout/Layout'
import { apiClient } from './axios.config'
import { useAppDispatch } from './store/createStore'
import { setObjectId, setSelectedLang } from './store/slices/appSlice'
import {
  clearAll,
  setSelectedHouse,
  setSelectedMunicipality,
  setPriceListId,
} from './store/slices/builderSlice'
import ErrorHandler from './components/ErrorHandler'
import useAsyncDispatch from './hooks/useAsyncDispatch'

export default () => {
  // const HouseProjects = lazy(() => import('./routes/HouseProjects'))
  // const HouseBuilder = lazy(() => import('./routes/HouseBuilder'))
  const RestoreQuickConfig = lazy(() => import('./routes/RestoreQuickConfig'))
  const HouseBuilder = lazy(() => import('./routes/HouseBuilderSimple'))
  const HouseOverview = lazy(() => import('./routes/HouseOverview'))
  const HouseChooser = lazy(() => import('./routes/HouseChooser'))
  const HouseOverviewPDF = lazy(() => import('./routes/house-overview-pdf/HouseOverviewPDF'))

  const location = new ReactLocation()
  const dispatch = useAppDispatch()
  const asyncDispatch = useAsyncDispatch()

  const setupApplicationFromSearch = async ({ search }: { search: Search<any> }) => {
    const { lang, objectId, priceListId, municipality, externalBuildingId, houseModel } = search

    lang && dispatch(setSelectedLang(lang.toUpperCase()))
    objectId && dispatch(setObjectId(objectId))
    priceListId && dispatch(setPriceListId(priceListId))

    if (municipality) {
      const { data: municipalities } = await apiClient.get('Municipality')
      const found = find(municipalities, (m) =>
        includes(m.name.toLowerCase(), municipality.toLowerCase()),
      )

      found && dispatch(setSelectedMunicipality(found))
    }

    if (externalBuildingId) {
      await asyncDispatch(clearAll(true), 'builder')
      await asyncDispatch(
        setSelectedHouse(externalBuildingId),
        'builder.building.externalBuildingId',
      )
    }

    if (houseModel) {
      const { data: houses } = await apiClient('/HouseModel')
      const { id } = houses.find(({ slug }: { slug: string }) => slug === houseModel) || {}

      if (id) {
        await asyncDispatch(clearAll({ externalBuildingId: id }), 'builder')
      }
    }

    return {}
  }

  useLayoutEffect(() => {
    // @ts-ignore
    s247r('environment', process.env.NODE_ENV)
  }, [])

  return (
    <>
      <ErrorHandler />
      <Suspense
        fallback={
          <div className="flex justify-center mt-4">
            <CgSpinnerTwoAlt className="animate-spin text-secondary text-[2.5rem]" />
          </div>
        }
      >
        <Router
          location={location}
          routes={[
            {
              path: 'overview-print',
              element: <HouseOverviewPDF />,
            },
            {
              element: <Layout />,
              children: [
                {
                  title: 'Byggonline | Tillval',
                  path: 'builder',
                  element: <HouseBuilder />,
                },
                {
                  title: 'Byggonline | Överblick',
                  path: 'overview',
                  element: <HouseOverview />,
                },
                {
                  title: 'Byggonline | Välj ett hus',
                  children: [
                    {
                      title: 'Byggonline | Välj ett hus',
                      path: '/',
                      element: <HouseChooser />,
                    },
                    {
                      path: ':code',
                      element: <RestoreQuickConfig />,
                      loader: async ({ params: { code } }) => ({
                        config: await apiClient(`/QuickConfiguration/${code}`)
                          .then((response) => response.data)
                          .then((data) => ({ key: data.key, data: JSON.parse(data.data) })),
                      }),
                    },
                  ],
                },
              ],
              loader: setupApplicationFromSearch,
            },
          ]}
        />
      </Suspense>
    </>
  )
}
