import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend from 'i18next-chained-backend'
import _ from 'lodash'

const saveMissing = () => process.env.SAVE_MISSING_TRANSLATIONS === 'true'

const CBackend = new ChainedBackend(null, {
  backends: [
    LocalStorageBackend, // primary
    HttpBackend, // fallback
  ],
  backendOptions: [
    {
      prefix: 'i18next_res_',
      expirationTime: process.env.NODE_ENV === 'development' ? 0 : 2 * 60 * 1000,
    },
    {
      loadPath: `${process.env.BYGGONLINE_API_URL}locales/{{lng}}/{{ns}}`,
      addPath: saveMissing()
        ? `${process.env.BYGGONLINE_API_URL}admin/locales/addjson/{{lng}}/{{ns}}`
        : '',
      parsePayload(namespace: string, key: string) {
        return { key, text: '' }
      },
      customHeaders: saveMissing()
        ? {
            Authorization: `Basic ${process.env.BYGGONLINE_AUTH}`, // ONLY use in dev or test!
            // 'content-type': 'application/x-www-form-urlencoded'
          }
        : null,
    },
  ],
})

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  customLanguageDetection: () => 'sv',
  caches: ['localStorage'],
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(CBackend)
  .init({
    detection: DETECTION_OPTIONS,
    ns: ['customer-frontend'],
    defaultNS: 'customer-frontend',
    fallbackLng: 'sv',
    supportedLngs: ['sv', 'no'],
    saveMissing: saveMissing(),
    debug: false,
    keySeparator: false, // we do not use keys in form messages.welcome
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
