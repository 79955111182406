import { findIndex, map } from 'lodash'
import update from 'immutability-helper'
import Toast from './Toast'
import { useAppDispatch, useAppSelector } from '../store/createStore'
import { setErrors } from '../store/slices/errorSlice'
import * as styles from './ErrorHandler.module.scss'

interface Error {
  key: string
  message: string
}

const ErrorHandler = () => {
  const dispatch = useAppDispatch()
  const errors = useAppSelector((state) => state.error.errors)

  const handleOpenChange = (open: boolean, error: Error) => {
    if (!open) {
      const index = findIndex(errors, { key: error.key })
      dispatch(
        setErrors(
          update(errors, {
            $splice: [[index, 1]],
          }),
        ),
      )
    }
  }
  return (
    <div className={styles.errorHandler}>
      {map(errors, (error, index) => (
        <Toast
          key={error.key}
          open={!!error}
          onOpenChange={(open) => handleOpenChange(open, error)}
          title="Error"
          description={error?.message + index}
          error
        />
      ))}
    </div>
  )
}

export default ErrorHandler
