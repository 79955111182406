import { FiShare, AiFillMail, IoLink, CgSpinner } from 'react-icons/all'
import { useLocation } from '@tanstack/react-location'
import { useRef, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { setQuickConfigCode } from '../store/slices/builderSlice'
import { useAppDispatch, useAppSelector } from '../store/createStore'
import useProductData from '../hooks/useProductData'
import Toast from './Toast'
import Button from './Button'
import Popover from './Popover'
import usePrevious from '../hooks/usePrevious'
import { saveQuickConfig } from '../utils/quickConfigUtils'
import useAnalyticsEventTracker from '../hooks/useAnalyticsEventTracker'
import useStaleContent from '../hooks/useStaleContent'

const QuickConfiguration = () => {
  const [toast, setToast] = useState<string | null>()
  const [saving, setSaving] = useState(false)
  const timerRef = useRef<number | null>(null)
  const location = useLocation()
  const { selections } = useProductData()
  const { builder } = useAppSelector((state) => state)
  const { building } = builder
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const gaEventTracker = useAnalyticsEventTracker('QuickConfig')
  const isStale = useStaleContent()
  const emailSubject = t('email_subject')
  const emailBody = `${t('email_body')} - %0D%0A`

  const previousBuilding = usePrevious(building?.productGroupCategories)

  const isHidden = useMemo(
    () => !!location.current.pathname.match('^/?[A-Z0-9]{6}$') || isEmpty(selections),
    [location.current, selections],
  )

  const handleSave = (manual: boolean) => {
    setSaving(true)
    return saveQuickConfig(builder)
      .then((data) => {
        !builder.quickConfigCode && dispatch(setQuickConfigCode(data.key))
        manual && setToast(t('quickConfig_toastDescription-new'))
        timerRef.current && clearInterval(timerRef.current)
        setTimeout(() => {
          setSaving(false)
        }, 750)
        return data.key
      })
      .catch(() => {
        setSaving(false)
        return null
      })
  }

  const handleCopyClick = () => {
    gaEventTracker('copy')
    if (builder.quickConfigCode) {
      navigator.clipboard.writeText(
        `${window.location.origin.toString()}/${builder.quickConfigCode}`,
      )
      setToast(t('quickConfig_toastDescription-copy'))
    } else {
      handleSave(true).then((key: string) => {
        navigator.clipboard.writeText(`${window.location.origin.toString()}/${key}`)
        setToast(t('quickConfig_toastDescription-copy'))
      })
    }
  }

  const handleMailClick = () => {
    gaEventTracker('mail')
    const openMailClient = (key: string) => {
      const body = `${window.location.origin.toString()}/${key}`
      window.open(`mailto:?body=${encodeURIComponent(body)}`)
    }
    if (builder.quickConfigCode) {
      openMailClient(builder.quickConfigCode)
    } else {
      handleSave(true).then((key) => {
        openMailClient(key)
      })
    }
  }

  const handleToastChange = (open: boolean) => {
    !open && setToast(null)
  }

  const hasChanges = useMemo(
    () => previousBuilding !== building?.productGroupCategories,
    [previousBuilding, building?.productGroupCategories],
  )

  const clearTimer = () => {
    timerRef.current && clearInterval(timerRef.current)
  }

  useEffect(() => {
    if (hasChanges && builder.quickConfigCode) {
      clearTimer()
      timerRef.current = window.setInterval(() => {
        handleSave(false)
      }, 3000)
    } else {
      clearTimer()
    }
  }, [previousBuilding, building?.productGroupCategories, builder.quickConfigCode])

  useEffect(
    () => () => {
      clearTimer()
    },
    [],
  )

  const shareContent = () => (
    <div className="flex flex-col space-y-2 uppercase">
      <div
        className="flex space-x-2 items-center cursor-pointer text-secondary hover:text-secondary-600 group"
        onClick={handleMailClick}
      >
        <AiFillMail />{' '}
        <span className="group-hover:-translate-x-1 transition-all">{t('quickConfig_email')}</span>
      </div>
      <div
        className="flex space-x-2 items-center cursor-pointer text-secondary hover:text-secondary-600 group"
        onClick={handleCopyClick}
      >
        <IoLink />{' '}
        <span className="group-hover:-translate-x-1 transition-all">
          {t('quickConfig_copyLink')}
        </span>
      </div>
    </div>
  )

  return (
    <>
      {!isStale && (
        <Popover content={shareContent()}>
          <div className={classNames('', { hidden: isHidden })}>
            <Button size="sm" tag="div">
              {saving ? (
                <CgSpinner className="text-lg mr-0 sm:mr-2 animate-spin" />
              ) : (
                <FiShare className="text-lg mr-0 sm:mr-2" />
              )}
              <span className="hidden sm:inline">{t('quickConfig_share')}</span>
            </Button>
          </div>
        </Popover>
      )}

      <Toast
        open={!!toast}
        title={t('quickConfig_toastTitle')}
        description={toast ?? ''}
        onOpenChange={handleToastChange}
        duration={10000}
      >
        <div
          className="cursor-pointer text-secondary"
          onClick={() => {
            navigator.clipboard.writeText(
              `${window.location.origin.toString()}/${builder.quickConfigCode}`,
            )
          }}
        >
          {`${window.location.origin.toString()}/${builder.quickConfigCode}`}
        </div>
      </Toast>
    </>
  )
}

export default QuickConfiguration
