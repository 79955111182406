.errorHandler {
  @apply fixed bottom-0 right-0 flex flex-col;

  > div {
    position: relative;

    > ol {
      position: relative;
    }
  }
}
