import { useTranslation } from 'react-i18next'
import logo from '../assets/images/logo.svg'
import LangSelector from '../components/lang-selector/LangSelector'
import QuickConfiguration from '../components/QuickConfiguration'
import { useAppSelector } from '../store/createStore'

const Header = () => {
  const {
    builder: { publishedPriceList, previousPriceList },
    app: { lang },
  } = useAppSelector((state) => state)

  const { priceListId } = useAppSelector((state) => state.builder)
  const { t } = useTranslation()
  const redirectURL = t('header_redirectUrl')

  const popoverContent = () => (
    <div className="flex text-base">{t('header_outdatedPriceListWarning')}</div>
  )

  return (
    <header
      id="header"
      className="h-[64px] m-h-[64px] px-4 flex items-center justify-between bg-primary text-white p-2 fixed top-0 z-50 w-full transition-all duration-300"
    >
      <a href={redirectURL}>
        <img src={logo} className="h-[20px] w-auto" alt="" />
      </a>

      {(publishedPriceList !== null || previousPriceList !== null) &&
        !publishedPriceList &&
        !previousPriceList &&
        priceListId && (
          <div className="flex items-center justify-end space-x-2">
            <div className="items-center justify-center md:flex">
              <div className="text-xl font-bold text-orange-500">FÖRHANDSGRANSKNING</div>
            </div>
          </div>
        )}

      <div className="flex items-center justify-end space-x-2">
        <div className="flex items-center space-x-2 hidden">
          <div className="w-[26px] h-[26px] rounded-full bg-tertiary flex items-center justify-center">
            <i className="icon-user text-primary text-[24px]" />
          </div>
          <div className="items-center justify-center md:flex">
            <div className="text-[15px] font-bold">Namn Namnsson</div>
            <i className="icon-chevron-down" />
          </div>
        </div>

        <QuickConfiguration />
        <LangSelector />
      </div>
    </header>
  )
}

export default Header
